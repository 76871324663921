<template>
  <div class="Statisticstable">
    <div class="tit">
        <el-select class="box one" clearable v-model="procuratorate" placeholder="请选择检察院">
          <el-option
            v-for="item in prolist"
            :key="item.id"
            :label="item.procuratoratename"
            :value="item.id">
          </el-option>
        </el-select>
        <el-select class="box two" clearable v-model="cardname" placeholder="请选择案件类型">
          <el-option
            v-for="item in cardlist"
            :key="item.id"
            :label="item.cardname"
            :value="item.id">
          </el-option>
        </el-select>
        <el-select class="box two" clearable v-model="visitstatusname" placeholder="请选择审核状态">
          <el-option
            v-for="item in visitstatuslist"
            :key="item.id"
            :label="item.visitstatusname"
            :value="item.id">
          </el-option>
        </el-select>
        <el-date-picker
          v-model="value2"
          type="daterange"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期">
        </el-date-picker>
        <el-button class="box submit" type="primary" icon="el-icon-search" @click="search">搜索</el-button>
    </div>
    <div class="content">
      <div class="contlist">
        <div class="list">
          <div class="main" id="main1"></div>
        </div>
        <div class="list">
          <div class="main" id="main2"></div>
        </div>
        <div class="list">
          <div class="main" id="main3"></div>
        </div>
      </div>
      <div class="contlist">
        <div class="list">
          <div class="main" id="main4"></div>
        </div>
        <div class="list">
          <div class="main" id="main5"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAdminMyphotoAll,getStaticAll,getDocuAll,getVisitsList } from "@/utils/api";
export default {
    data(){
        return{
          prolist:[],  //检察院列表
          procuratorate:'',
          cardlist:[],  //案件类型列表
          cardname:'',
          visitstatuslist:[], //审核状态列表
          visitstatusname:'',
          value2:'',//开始时间和结束时间
        }
    },
    methods: {
      // 获取字典
      getdic(){
        getStaticAll().then(res =>{
          // console.log(res);
          this.visitstatuslist = res.data.data
        })
        getDocuAll().then(res =>{
          // console.log(res);
          this.cardlist = res.data.data
        })
        let data = {
          pid:'',   //检察院id
          did:'',   //案件类型id
          vid:'',   //审核状态id
          userId:'',   //用户
          phone:'',   //手机号
          startsTime:'',   //开始时间
          endTime:'',   //结束时间
          limit:1,  //第几页
          page:10,  //每页数量
        }
        getVisitsList(data).then(res =>{
          // console.log('接访列表',res);
          this.prolist = res.data.data.userId
        })
      },
      // 获取数据
      getchartlist(starttime,endtime){
        if(this.procuratorate == ''){
          var proid = 0
        }else{
          var proid = this.procuratorate
        }
        // console.log('时间',starttime,endtime);
        getAdminMyphotoAll({
          proId:proid,   //检察院id
          stateId:this.visitstatusname,   //状态id
          typeId:this.cardname,    //案件类型id
          beginTime:starttime,   //开始时间
          endTime:endtime    //结束时间
        }).then(res =>{
          // console.log(res);
          this.drawChartone(res.data.data.Visits);
          this.drawCharttwo(res.data.data.Sex);
          this.drawChartthree(res.data.data.Type);
          this.drawChartfour(res.data.data.Audit);
          this.drawChartfive(res.data.data.Sum);
        })
      },
      // 搜索
      search(){
        // console.log(this.value2);
        let endTime = '';
        let startTime = '';
        if(this.value2 !== '' && this.value2 !== null){
          endTime = this.value2[1] ;
          startTime = this.value2[0];
          endTime=endTime.getFullYear() + '-' + (endTime.getMonth() + 1) + '-' + endTime.getDate();
          startTime=startTime.getFullYear() + '-' + (startTime.getMonth() + 1) + '-' + startTime.getDate();
        }else{
          endTime = '';
          startTime = '';
        }
        this.getchartlist(startTime,endTime)
      },
      drawChartone(data) {
        // 基于准备好的dom，初始化echarts实例  这个和上面的main对应
        let myChart = this.$echarts.init(document.getElementById("main1"));
        // 指定图表的配置项和数据
        let option = {
          title:{
            text:'选中各检察院接访数占比图',
            left:"center",
            padding: [40,40,100,100],
          },
          tooltip: {
            trigger: 'item'
          },
          legend: {
            orient: 'horizontal',
            bottom: 10,
            itemWidth: 16, //图例颜色块的宽度和高度
            itemHeight: 16,
            icon:"circle",
          },
          series: [
            {
              type: 'pie',
              radius: '50%',
              data: data,
              label: {
                normal: {
                  show: true,
                  position: 'inner', // 数值显示在内部
                },
              },
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        };
        // 使用刚指定的配置项和数据显示图表。
        myChart.setOption(option);
      },
      drawCharttwo(data) {
        // 基于准备好的dom，初始化echarts实例  这个和上面的main对应
        let myChart = this.$echarts.init(document.getElementById("main2"));
        // 指定图表的配置项和数据
        let option = {
          title:{
            text:'预约人性别占比图',
            left:"center",
            padding: [40,40,100,100],
          },
          tooltip: {
            trigger: 'item'
          },
          legend: {
            orient: "horizontal",
            bottom: 10,
            itemWidth: 16, //图例颜色块的宽度和高度
            itemHeight: 16,
            icon:"circle",
          },
          series: [
            {
              type: 'pie',
              radius: '50%',
              data: data,
              label: {
                normal: {
                  show: true,
                  position: 'inner', // 数值显示在内部
                },
              },
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        };
        // 使用刚指定的配置项和数据显示图表。
        myChart.setOption(option);
      },
      drawChartthree(data) {
        // 基于准备好的dom，初始化echarts实例  这个和上面的main对应
        let myChart = this.$echarts.init(document.getElementById("main3"));
        // 指定图表的配置项和数据
        let option = {
          title:{
            text:'案件类型占比图',
            left:"center",
            padding: [40,40,100,100],
          },
          tooltip: {
            trigger: 'item'
          },
          legend: {
            orient: "horizontal",
            bottom: 10,
            itemWidth: 16, //图例颜色块的宽度和高度
            itemHeight: 16,
            icon:"circle",
          },
          series: [
            {
              type: 'pie',
              radius: '50%',
              data: data,
              label: {
                normal: {
                  show: true,
                  position: 'inner', // 数值显示在内部
                },
              },
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        };
        // 使用刚指定的配置项和数据显示图表。
        myChart.setOption(option);
      },
      drawChartfour(data) {
        // 基于准备好的dom，初始化echarts实例  这个和上面的main对应
        let myChart = this.$echarts.init(document.getElementById("main4"));
        // 指定图表的配置项和数据
        let option = {
          title:{
            text:'审核情况',
            left:"center",
            padding: [40,40,100,100],
          },
          tooltip: {
            trigger: 'item'
          },
          legend: {
            orient: "horizontal",
            bottom: 10,
            itemWidth: 16, //图例颜色块的宽度和高度
            itemHeight: 16,
            icon:"circle",
          },
          series: [
            {
              type: 'pie',
              radius: '50%',
              data: data,
              label: {
                normal: {
                  show: true,
                  position: 'inner', // 数值显示在内部
                },
              },
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        };
        // 使用刚指定的配置项和数据显示图表。
        myChart.setOption(option);
      },
      drawChartfive(data) {
        // 基于准备好的dom，初始化echarts实例  这个和上面的main对应
        let myChart = this.$echarts.init(document.getElementById("main5"));
        // 指定图表的配置项和数据
        let option = {
          title:{
            text:'审核人工作量占比',
            left:"center",
            padding: [40,40,100,100],
          },
          tooltip: {
            trigger: 'item'
          },
          legend: {
            orient: "horizontal",
            bottom: 10,
            itemWidth: 16, //图例颜色块的宽度和高度
            itemHeight: 16,
            icon:"circle",
          },
          series: [
            {
              type: 'pie',
              radius: '50%',
              data: data,
              label: {
                normal: {
                  show: true,
                  position: 'inner', // 数值显示在内部
                },
              },
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        };
        // 使用刚指定的配置项和数据显示图表。
        myChart.setOption(option);
      },
    },
    mounted() {
      let endTime = '';
      let startTime = '';
      this.getdic()
      this.getchartlist(startTime,endTime)
    },
}
</script>

<style lang="less" scoped>
.Statisticstable{
  width: auto;
  min-height: 95%;
  background: #FFFFFF;
  box-shadow: 0px 3px 20px 0px rgba(131,131,131,0.1800);
  border-radius: 4px;
  padding: 24px;
  box-sizing: border-box;
  position: relative;
  .tit{
    display: flex;
    .box{
      margin-right: 20px;
    }
    .five{
      width: 287px;
    }
    .submit{
      width: 94px;
      margin-left: 15px;
    }
  }
  .content{
    width: auto;
    margin-top: 20px;
    .contlist{
      display: flex;
      .list{
        flex: 1;
        height: 400px;
        .main{
          width: 100%;
          height: 100%;
        }
        .title{
          line-height: 50px;
          text-align: center;
          border-bottom: 1px solid rgb(220, 209, 209);
        }
      }
    }
  }
}
</style>